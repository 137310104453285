import * as React from "react"

import {
    AutocompleteInput,
    BooleanField,
    BooleanInput,
    Datagrid,
    Edit,
    EditGuesser,
    List,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    Show,
    SimpleForm,
    SimpleList,
    SimpleShowLayout,
    TextField,
    TextInput,
    UrlField,
    useEditContext,
    useListContext,
    useRecordContext,
    useShowContext,
    useShowController,
} from "react-admin"

import { Container } from "../components/container"
import ListActionsTopToolbar from "../components/list_actions"
import PostIcon from "@mui/icons-material/Book"
import { PostPagination } from "../components/post_pagination"
import Typography from "@mui/material/Typography"

export const DevicesList = () => (
    <Container>
        <Typography variant="h4">Devices</Typography>
        <List
            pagination={<PostPagination />}
            perPage={25}
            // actions={<ListActionsTopToolbar />}
        >
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField source="name" />
                <TextField source="description" />
                <ReferenceField
                    source="property_id"
                    reference="properties"
                    link="show"
                >
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="user_id" reference="users">
                    <TextField source="id" />
                </ReferenceField>
            </Datagrid>
        </List>
    </Container>
)

const DevicesShow = () => {
    const { record } = useShowController()

    return (
        <Container>
            <Typography variant="h4">{record && record.name}</Typography>
            <Show title={record && record.name}>
                <SimpleShowLayout sx={{ maxWidth: 500, width: 500 }}>
                    <TextField source="id" disabled fullWidth />
                    <TextField source="name" fullWidth />
                    <TextField source="description" />
                    <ReferenceField
                        source="property_id"
                        reference="properties"
                        perPage={100}
                    >
                        <TextField source="name" label="Property" />
                    </ReferenceField>
                </SimpleShowLayout>
            </Show>
        </Container>
    )
}

const DevicesEdit = () => {
    const { record } = useEditContext()

    return (
        <>
            <Container>
                <Typography variant="h4">
                    Edit {record && record.name}
                </Typography>
                <Edit title={record.name}>
                    <SimpleForm sx={{ maxWidth: 500 }}>
                        <TextInput source="id" disabled />
                        <TextInput source="name" />
                        <TextInput source="description" />
                        <TextInput source="unit_type" disabled />
                    </SimpleForm>
                </Edit>
            </Container>
            <Container>{/* <Button>Send Error Alert</Button> */}</Container>
        </>
    )
}

const settings = {
    list: DevicesList,
    show: DevicesShow,
    edit: DevicesEdit,
    icon: PostIcon,
}

export default settings
