import { fetchUtils } from "ra-core"
import { stringify } from "query-string"

/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 *
 * @example
 *
 * getList          => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * getOne           => GET http://my.api.url/posts/123
 * getManyReference => GET http://my.api.url/posts?author_id=345
 * getMany          => GET http://my.api.url/posts?id=123&id=456&id=789
 * create           => POST http://my.api.url/posts/123
 * update           => PUT http://my.api.url/posts/123
 * updateMany       => PUT http://my.api.url/posts/123, PUT http://my.api.url/posts/456, PUT http://my.api.url/posts/789
 * delete           => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 * import jsonServerProvider from 'ra-data-json-server';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={jsonServerProvider('http://jsonplaceholder.typicode.com')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */
const apiClient = (apiUrl, httpClient = fetchUtils.fetchJson) => ({
    getList: (resource, params) => {
        const { field, order } = params.sort
        const { page, perPage } = params.pagination
        const query = {
            ...fetchUtils.flattenObject(params.filter),
            sort: field,
            order: order,
            start: (page - 1) * perPage,
            limit: perPage,
        }

        const url = `${apiUrl}/${resource}?${stringify(query)}`

        return httpClient(url).then(({ headers, json }) => {
            if (!headers.has("x-total-count")) {
                throw new Error(
                    "The X-Total-Count header is missing in the HTTP Response.",
                )
            }
            return {
                data: json,
                total: parseInt(
                    headers.get("x-total-count").split("/").pop(),
                    10,
                ),
            }
        })
    },

    getOne: (resource, params) => {
        const { id, ad_source } = params

        let query = { ad_source }

        return httpClient(
            `${apiUrl}/${resource}/${id}?${stringify(query)}`,
        ).then(({ json }) => ({
            data: json,
        }))
    },

    getMany: (resource, params) => {
        const query = {
            id: params.ids,
        }
        const url = `${apiUrl}/${resource}?${stringify(query)}`
        return httpClient(url).then(({ json }) => ({ data: json }))
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination
        const { field, order } = params.sort
        const query = {
            ...fetchUtils.flattenObject(params.filter),
            [params.target]: params.id,
            sort: field,
            order: order,
            start: (page - 1) * perPage,
            limit: perPage,
        }
        const url = `${apiUrl}/${resource}?${stringify(query)}`

        return httpClient(url).then(({ headers, json }) => {
            if (!headers.has("x-total-count")) {
                throw new Error(
                    "The X-Total-Count header is missing in the HTTP Response.",
                )
            }
            return {
                data: json,
                total: parseInt(
                    headers.get("x-total-count").split("/").pop(),
                    10,
                ),
            }
        })
    },

    update: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: "PUT",
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json })),

    // json-server doesn't handle filters on UPDATE route,
    // so we fallback to calling UPDATE n times instead
    updateMany: (resource, params) =>
        Promise.all(
            params.ids.map((id) =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: "PUT",
                    body: JSON.stringify(params.data),
                }),
            ),
        ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),

    create: (resource, params) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: "POST",
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        })),

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: "DELETE",
        }).then(({ json }) => ({ data: json })),

    // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) =>
        Promise.all(
            params.ids.map((id) =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: "DELETE",
                }),
            ),
        ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),
})

export default apiClient
