import React from "react"
import ReactDOM from "react-dom/client"
import * as Sentry from "@sentry/react"

import App from "./App"
import { BrowserTracing } from "@sentry/tracing"
import "./index.css"

Sentry.init({
    dsn: "https://9bc8c4857b6f4aa9a5ce282de157d285@o4503965055188992.ingest.sentry.io/4503965056499712",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
})

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
)
