import * as React from "react"

import { AppBar, UserMenu } from "react-admin"
import { Box, Typography, useMediaQuery } from "@mui/material"

const CustomUserMenu = () => <UserMenu>{/* <ConfigurationMenu /> */}</UserMenu>

const CustomAppBar = (props) => {
    const isLargeEnough = useMediaQuery((theme) => theme.breakpoints.up("sm"))
    return (
        <AppBar
            {...props}
            color="secondary"
            elevation={1}
            userMenu={<CustomUserMenu />}
        >
            <Typography
                variant="h6"
                color="inherit"
                sx={{
                    flex: 1,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                }}
                id="react-admin-title"
            />
            {isLargeEnough && <h3>Cappsule Admin</h3>}
            {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
        </AppBar>
    )
}

export default CustomAppBar
