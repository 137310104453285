import {
    Create,
    SimpleForm,
    TextInput,
    DateInput,
    required,
    ListGuesser,
    SelectInput,
    ReferenceInput,
} from "react-admin"

import ReportProblemIcon from "@mui/icons-material/ReportProblem"

export const AlertCreate = () => (
    <Create>
        <SimpleForm>
            <ReferenceInput
                label="Device"
                source="device_id"
                reference="devices"
            >
                <SelectInput label="Device" />
            </ReferenceInput>

            <SelectInput
                source="alert_level"
                choices={[
                    { id: "alert", name: "Error" },
                    { id: "ok", name: "OK" },
                ]}
            />
            <TextInput source="message" validate={[required()]} fullWidth />
        </SimpleForm>
    </Create>
)

const settings = {
    // show: DevicesShow,
    // edit: DevicesEdit,
    create: AlertCreate,
    list: ListGuesser,
    icon: ReportProblemIcon,
}

export default settings
