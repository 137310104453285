import * as React from "react"

import { Admin, CustomRoutes, ListGuesser, Resource } from "react-admin"

import { API_ENDPOINT } from "./config"
import HomePage from "./pages/home"
import { Layout } from "./layout"
import { lightTheme } from "./layout/themes"
import apiClient from "./client"

import alerts from "./pages/alerts"
import devices from "./pages/devices"
// import electorates from "./pages/electorates"

export const dataProvider = apiClient(API_ENDPOINT)

const App = () => (
    <Admin
        dataProvider={dataProvider}
        // dashboard={HomePage}
        title={"Cappsule Admin"}
        disableTelemetry
        theme={lightTheme}
        layout={Layout}
    >
        <Resource name="alerts" {...alerts} />
        <Resource name="devices" {...devices} />
        <Resource name="properties" list={ListGuesser} />
        <Resource name="statistics" list={ListGuesser} />
        <Resource name="users" list={ListGuesser} />
    </Admin>
)

export default App
